<template>
  <div class="bg">
    <div class="margin">
      <div class="text-right desktop">
        <b-img width="240" class="mt-5" src="./img/logo-preta.png"></b-img>
      </div>
      <div class="text-left ml-3 mb-5 desktop">
        <a href="https://seguro.bikeregistrada.com.br/" class="underline">Ir para a página inicial do site</a>
      </div>
      <div class="text-center pt-3 mobile">
        <a href="https://seguro.bikeregistrada.com.br/" class="underline">Ir para a página inicial do site</a>
      </div>

      <div class="row mr-0 ml-0">
        <div class="col-sm-12 col-md-6">

          <h4 class="title-thanks mt-4 mb-4 bold desktop">Estamos quase lá! Suas informações e pagamento estão sendo
            validados agora.
          </h4>

          <h5 class="title-thanks mt-4 mb-4 bold mobile" style="text-align: center;">Estamos quase lá! Suas informações
            e pagamento estão sendo validados agora.
          </h5>

          <!-- <div class="d-flex">
            <img src="/img/clock-icon.svg" width="41px" />
            <span class="gray self-center ml-3">Seu cadastro está pendente e, assim que for aprovado, enviaremos um
              e-mail de confirmação. Você também será notificado pelo WhatsApp fornecido no momento do cadastro.
            </span>
          </div> -->
          <div class="d-flex mt-4">
            <img src="/img/smartphone-icon.svg" width="41px" />
            <span class="gray self-center ml-3">Esteja atento a seu WhatsApp e e-mail cadastrados! Seu especialista Bike
              Registrada te deixará informado por lá sobre seus próximos passos e fases da sua proteção.
            </span>
          </div>
          <div class="d-flex mt-4">
            <img src="/img/whatsapp-icon.svg" width="41px" />
            <span class="gray self-center ml-3">Qualquer dúvida, entre em contato com a equipe pelo WhatsApp.
            </span>
          </div>
          <!-- <div class="d-flex mt-4 pointer" @click.prevent="showModalCoberturaProvisoria = true">
            <img src="/img/clock-icon.svg" width="41px" />
            <span class="gray self-center ml-3 underline">Realize a vistoria da sua bike agora para ter a sua <span
                class="bolder">COBERTURA</span> provisória
              e garanta a segurança da sua bike <span class="bolder">NESTE MOMENTO</span></span>
          </div> -->

          <div class="d-flex mt-5 desktop">
            <button class="continue-btn ml-3" @click.prevent="whatsapp">FALAR COM MEU ESPECIALISTA</button>
          </div>
          <div class="mt-5 mb-5 mobile">
            <button class="continue-btn" @click.prevent="whatsapp">FALAR COM MEU ESPECIALISTA</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";
import Swal from "sweetalert2";
import config from "../config";
import formatacaoMoeda from "../helpers/formatacaoMoeda";
import captureError from "../helpers/captureError";
import gtmError from "../helpers/gtmError";

export default {
  components: {
  },

  data() {
    return {
      config,
      formatacaoMoeda,
      loadingGeral: false,
      abrirModal: false,
      exibirProgresso: false,
      seguro: {},
      showModalCoberturaProvisoria: false
    };
  },
  async created() {
    var strDadosLocalStorage = sessionStorage.getItem("dadosSimulacao");
    localStorage.removeItem("idSeguro");
    localStorage.removeItem("dadosSimulacao");
    if (!strDadosLocalStorage) {
      this.$router.replace("/login");
    }
    localStorage.removeItem("UserInfo");

    let dadosCotacao = JSON.parse(strDadosLocalStorage) || {};
    var idSeguro = dadosCotacao.id;
    dadosCotacao.passoCheckout == false;
    var result = await this.popularInformacoes(idSeguro);

    if (!result) {
      //Redirecionar para a home
      this.$router.replace("/login");
    }

    //Converte apenas se ja não converteu antes
    var jaConverteu = this.verificarJaConverteuGoogleAnalytics(idSeguro);
    if (!jaConverteu) {
      var converteu = this.conversaoGoogleAnalytics(
        idSeguro,
        this.seguro.plano,
        this.seguro.valorPlano
      );
      if (converteu) {
        this.registrarConversaoLocalStorage(idSeguro);
      }
    }
  },
  methods: {
    whatsapp() {
      window.open(
        "https://api.whatsapp.com/send?phone=556135224521&text=Olá, fiz meu seguro e preciso de mais informações.",
        "_blank"
      );
    },
    verificarJaConverteuGoogleAnalytics(idSeguro) {
      var dados = this.buscarConversoesLocalStorage();

      var possuiConversao = !!dados[idSeguro];
      return possuiConversao;
    },
    buscarConversoesLocalStorage() {
      var dados = {};

      try {
        var conteudo = window.localStorage.getItem("ConGA_Obrig");
        if (conteudo) {
          dados = JSON.parse(conteudo);
        }
      } catch (error) {
        captureError(error);
      }

      return dados;
    },
    registrarConversaoLocalStorage(idSeguro) {
      var dados = this.buscarConversoesLocalStorage();
      dados[idSeguro] = true;

      window.localStorage.setItem("ConGA_Obrig", JSON.stringify(dados));
    },
    conversaoGoogleAnalytics(transactionId, nomeProduto, valor) {
      try {
        if (window["gtag"] && config.ENABLE_MARKETING_TRACKERS) {
          window["gtag"]("event", "purchase", {
            transaction_id: transactionId,
            affiliation: "Seguro Bike Registrada",
            value: valor,
            currency: "BRL",
            tax: 0,
            shipping: 0,
            items: [{ name: nomeProduto, price: valor }],
          });

          window["gtag"]("event", "conversion", {
            transaction_id: transactionId,
            send_to: "AW-954482663/vI0YCL39xJMCEOf_kMcD",
          });

          return true;
        }
      } catch (e) {
        gtmError(e, {
          transaction_id: transactionId,
          affiliation: "Seguro Bike Registrada",
          value: valor,
          currency: "BRL",
          tax: 0,
          shipping: 0,
          name: nomeProduto,
          price: valor,
        });
      }
      return false;
    },
    vistoriaInspec() {
      window.open(this.seguro.urlInspec, '_blank');
    },
    async popularInformacoes(idSeguro) {
      try {
        if (!idSeguro) {
          return false;
        }

        this.loadingGeral = true;

        var response = await axios.get(`${config.API_URLV2}/seguro/${idSeguro}`);
        this.seguro = response.data;

        this.loadingGeral = false;
        return true;
      } catch (error) {
        captureError(error);

        await Swal.fire({
          title: "Não foi possível buscar seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return false;
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      this.anim.play();
    },
    onCompleteMsg() {
      this.exibirProgresso = true;
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url("https://fonts.googleapis.com/css?family=Open%20Sans:wght@100..900&display=swap");


.pointer {
  cursor: pointer;
}

.bolder {
  font-weight: bolder;
  color: #4FA935;
}

.title-thanks {
  color: #425994
}

.margin {
  margin: 0px 40px 0px 40px;
}

.self-center {
  align-self: center;
}

.continue-btn {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #4FA935;
  border-radius: 8px;
  background: #4FA935;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.ghost {
  text-align: center;
  padding: 10px 15px 10px 15px;
  border: 1px solid transparent;
  border-radius: 8px;
  background: #FFFFFF;
  color: #78849E;
  text-wrap: nowrap;
}

.bold {
  font-family: "Inter";
  font-weight: 700;
}

.underline {
  color: #4FA935 !important;
  text-decoration: underline;
}

.gray {
  color: #78849E;
}

.bg {
  background-image: url("/img/obrigado-checkout.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 30%;
  background-color: #F9F9F9;
  font-family: Inter !important;
  min-height: 100%;
}


@media (max-width: 768px) {
  .desktop {
    display: none !important
  }

  .continue-btn {
    width: 100%;
  }

  .ghost {
    width: 100%;
  }

  .margin {
    margin: 0px 20px 0px 20px;
  }

  .bg {
    background-image: none !important;
    background-color: #F9F9F9;
    font-family: Inter !important;
    min-height: 100%;
  }
}

@media (min-width: 769px) {
  .mobile {
    display: none !important
  }
}
</style>
